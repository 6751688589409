import {
  ProfileIcon,
  SupportIcon,
  BookmarkIcon,
  PackageIcon,
  Typography,
  RefundIcon,
} from '@objectedge/pecai-storefront-ds';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styles from './MyAccountMenu.module.scss';
import { Page } from '~/utils/navigation';

interface MenuItemProps {
  text: string;
  href?: string;
  icon?: React.ReactNode;
  active?: boolean;
}

const MenuItem = ({ icon, text, href = '#' }: MenuItemProps) => {
  const Route = useRouter();

  return (
    <Link href={href}>
      <a className={clsx(styles.menuItem, Route.pathname === href && styles.active)}>
        {icon}
        <Typography variant="body-text-2">{text}</Typography>
      </a>
    </Link>
  );
};

const Divider = () => (
  <div className={styles.divider}>
    <hr />
  </div>
);

export const MyAccountMenu = () => {
  return (
    <nav className={styles.myAccountMenu}>
      <MenuItem icon={<ProfileIcon size={24} />} text="Perfil" href={Page.perfil} />
      <MenuItem icon={<PackageIcon size={24} />} text="Pedidos" href={Page.pedidos} />
      <MenuItem icon={<RefundIcon size={24} />} text="Faturas" href={Page.myAccountInvoice} />
      <MenuItem icon={<BookmarkIcon size={24} />} text="Carrinhos" href={Page.carrinhos} />

      <Divider />
      <MenuItem icon={<SupportIcon size={24} />} text="Suporte" href={Page.ajuda} />
    </nav>
  );
};

export default MyAccountMenu;
