import {
  BookmarkIcon,
  Button,
  CartIcon,
  Container,
  Dropdown,
  ProfileAltIcon,
  Typography,
} from '@objectedge/pecai-storefront-ds';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useState } from 'react';
import { AuthenticationContext } from '~/components/context/Authentication';
import { Logo } from '~/components/icons';
import { useGetCategoryListQuery } from '~/operations';
import { useCart } from '~/utils/cart';
import { Page } from '~/utils/navigation';
import { RichTextProp } from '~/utils/prop-types';
import { CategoryListHeader } from './CategoryListHeader';
import styles from './HeaderDesktop.module.scss';
import { ProviderChangeModal } from './ProviderChangeModal';
import SubHeader from './SubHeader';
import { useLoginAndRegisterModal, ModalOpenEnum } from '~/utils/useLoginAndRegisterModal';
import { useMixpanelAnalytics } from '~/components/context/MixpanelAnalytics';

export interface HeaderDesktopProps {
  className: string;
  yellowBandText?: string | RichTextProp;
  darkBandText?: string | RichTextProp;
  showDarkBand?: boolean;
  showYellowBand?: boolean;
}

const UserDropdown = () => {
  const { provider, user, userPopulation, logout } = useContext(AuthenticationContext);
  const [modalOpen, setModalOpen] = useState(false);
  const { getTotalItemsInTheCart } = useCart();
  const cartQuantity = getTotalItemsInTheCart();
  const mixpanelAnalytics = useMixpanelAnalytics();
  const {
    loginRegisterModalStatus,
    handleGoToLoginModal,
    handleGoToRegisterModal,
    loginModalRender,
    registerModalRender,
  } = useLoginAndRegisterModal();

  const onClickHeaderMyCarts = () => {
    mixpanelAnalytics.click('header_saved_carts', { population: userPopulation });
  };

  const onClickDropdownMyCarts = () => {
    mixpanelAnalytics.click('dropdown_saved_carts', { population: userPopulation });
  };

  const items = [
    {
      id: 1,
      name: (
        <div className="d-flex justify-content-between align-items-center py-1">
          <a
            onClick={() => {
              setModalOpen(true);
            }}
            className={styles['commercial-name']}
          >
            <Typography variant="body-regular">
              Visualizando perfil de <b>{provider?.commercialName || user?.displayName}</b>
            </Typography>
          </a>
          <a
            onClick={() => {
              setModalOpen(true);
            }}
            className={styles['change-account-link']}
          >
            <Typography variant="body-regular" className="ml-2">
              Trocar
            </Typography>
          </a>
        </div>
      ),
    },
    {
      id: 2,
      name: (
        <Link href={Page.perfil}>
          <a>
            <Typography color="default">Perfil</Typography>
          </a>
        </Link>
      ),
    },
    {
      id: 3,
      name: (
        <Link href={Page.pedidos}>
          <a>
            <Typography color="default">Pedidos</Typography>
          </a>
        </Link>
      ),
    },
    {
      id: 4,
      name: (
        <Link href={Page.myAccountInvoice}>
          <a>
            <Typography color="default">Faturas</Typography>
          </a>
        </Link>
      ),
    },
    {
      id: 5,
      name: (
        <Link href={Page.carrinhos}>
          <a onClick={onClickDropdownMyCarts}>
            <Typography color="default">Carrinhos</Typography>
          </a>
        </Link>
      ),
    },
  ];

  return user ? (
    <>
      <Link href={Page.logout}>
        <a onClick={logout} className={styles['session-link']}>
          <Typography variant="body-regular" weight="medium">
            Sair
          </Typography>
        </a>
      </Link>
      <div className={styles['container-icons']}>
        <Link href={Page.carrinhos}>
          <a onClick={onClickHeaderMyCarts}>
            <BookmarkIcon size={32} color="secondary" />
          </a>
        </Link>
        <Link href={Page.cart}>
          <a className={styles['cart-container']}>
            <CartIcon size={32} color="white" className={styles['cart-icon']} />
            {cartQuantity > 0 && (
              <Typography className={styles['cart-quantity']} weight="bold">
                {cartQuantity}
              </Typography>
            )}
          </a>
        </Link>
        <Dropdown
          className={styles['login-dropdown']}
          dropdownItemsCustomStyles={styles['login-dropdown__items']}
          dropdownMenuCustomStyles={styles['login-dropdown__menu']}
          dropdownItems={items}
          showToggleIcon={false}
          enableOpenOnHover
          right
        >
          <ProfileAltIcon size={32} color="white" />
        </Dropdown>

        <ProviderChangeModal
          modalOpen={modalOpen}
          toggleModal={() => {
            setModalOpen(!modalOpen);
          }}
        />
      </div>
    </>
  ) : (
    <>
      <a onClick={() => handleGoToRegisterModal()} className={styles['session-link']}>
        <Typography variant="body-regular" weight="medium">
          Cadastre-se
        </Typography>
      </a>

      {loginRegisterModalStatus === ModalOpenEnum.REGISTER_MODAL && registerModalRender()}

      <Button onClick={() => handleGoToLoginModal()}>Entrar</Button>

      {loginRegisterModalStatus === ModalOpenEnum.LOGIN_MODAL && loginModalRender()}
    </>
  );
};

export const HeaderDesktop = ({
  className,
  darkBandText,
  yellowBandText,
  showDarkBand,
  showYellowBand,
}: HeaderDesktopProps) => {
  const { data: providerServices } = useGetCategoryListQuery();
  const categoryList = providerServices?.categoryList?.[0]?.children || [];
  const Router = useRouter();
  const styleActive = (href) => (Router?.pathname === href ? styles.active : undefined);
  const [showCategoryHeader, setShowCategoryHeader] = useState(false);

  const onServiceMouseEnter = () => {
    setShowCategoryHeader(true);
  };

  const onServiceMouseLeave = () => {
    setShowCategoryHeader(false);
  };

  return (
    <>
      <div className={clsx(styles['header__main'], className)}>
        <Container className={styles['header__container']}>
          <Link href={Page.home}>
            <a className={`d-inline-flex ${styles.header__container__logo}`}>
              <Logo />
            </a>
          </Link>
          <div className={styles.links}>
            <a onMouseEnter={onServiceMouseEnter} onMouseLeave={onServiceMouseLeave} onClick={onServiceMouseEnter}>
              <Typography variant="body-regular">Categorias</Typography>
            </a>
            {process.env.PECAAI_LP_URL && (
              <Link href={process.env.PECAAI_LP_URL}>
                <a className={styleActive('/sobre-a-tunne')} target="_blank">
                  <Typography variant="body-regular">Empresa</Typography>
                </a>
              </Link>
            )}
            <Link href={Page.ajuda}>
              <a className={styleActive('/faq')}>
                <Typography variant="body-regular">FAQ</Typography>
              </a>
            </Link>
            {process.env.WHATS_URL && (
              <Link href={process.env.WHATS_URL}>
                <a target="_blank">
                  <Typography variant="body-regular">Contato</Typography>
                </a>
              </Link>
            )}
          </div>
          <div className={styles['session-container']}>
            <UserDropdown />
          </div>
        </Container>

        <SubHeader
          darkBandText={darkBandText}
          yellowBandText={yellowBandText}
          showDarkBand={showDarkBand}
          showYellowBand={showYellowBand}
        />
      </div>

      {showCategoryHeader && (
        <CategoryListHeader
          categoryList={categoryList}
          onServiceMouseEnter={onServiceMouseEnter}
          onServiceMouseLeave={onServiceMouseLeave}
        />
      )}
    </>
  );
};

export default HeaderDesktop;
