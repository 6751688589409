import { BookmarkIcon, Button, CartMobileIcon, CloseIcon, MenuIcon, Typography } from '@objectedge/pecai-storefront-ds';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { AuthenticationContext } from '~/components/context/Authentication';
import { LogoMobile } from '~/components/icons';
import { useCart } from '~/utils/cart';
import { Page } from '~/utils/navigation';
import { RichTextProp } from '~/utils/prop-types';
import styles from './HeaderMobile.module.scss';
import { ProviderChangeModal } from './ProviderChangeModal';
import SubHeader from './SubHeader';
import { useLoginAndRegisterModal, ModalOpenEnum } from '~/utils/useLoginAndRegisterModal';
import { useMixpanelAnalytics } from '~/components/context/MixpanelAnalytics';

export interface HeaderMobileProps {
  className: string;
  yellowBandText?: string | RichTextProp;
  darkBandText?: string | RichTextProp;
  showDarkBand?: boolean;
  showYellowBand?: boolean;
}

const HeaderMobileMenu = ({ isOpen, toggleMenu }) => {
  const { provider, logout, user, userPopulation } = useContext(AuthenticationContext);
  const [modalOpen, setModalOpen] = useState(false);

  const {
    loginRegisterModalStatus,
    handleGoToLoginModal,
    handleGoToRegisterModal,
    loginModalRender,
    registerModalRender,
  } = useLoginAndRegisterModal();
  const mixpanelAnalytics = useMixpanelAnalytics();

  const clickBackdrop = (e) => {
    if (e.target !== e.currentTarget) return;
    toggleMenu();
  };

  if (!isOpen) return null;

  const onClickDropdownMyCarts = () => {
    mixpanelAnalytics.click('dropdown_saved_carts', { population: userPopulation });
  };

  return (
    <>
      <div className={styles.backdrop} onClick={clickBackdrop}>
        <div className={styles.submenu}>
          <div className={styles['submenu__header']}>
            <Link href={Page.home}>
              <a className="d-inline-flex d-flex align-items-center">
                <LogoMobile />
              </a>
            </Link>
            <CloseIcon size={32} color="white" onClick={toggleMenu} />
          </div>
          <div className={styles['submenu__content']}>
            <div className={styles['submenu__session']}>
              {user ? (
                <>
                  <Typography variant="body-regular">
                    Visualizando perfil de{' '}
                    <Typography variant="body-regular" weight="bold">
                      {provider?.commercialName || user?.displayName}
                    </Typography>
                  </Typography>
                  <Button outline onClick={() => setModalOpen(true)}>
                    <Typography variant="body-regular" weight="bold">
                      Trocar
                    </Typography>
                  </Button>
                  <Link href={Page.logout}>
                    <a onClick={logout}>
                      <Typography variant="body-regular" weight="medium" className={styles['submenu__logout']}>
                        Sair
                      </Typography>
                    </a>
                  </Link>
                  <Link href={Page.perfil}>
                    <a>
                      <Typography variant="body-regular">Perfil</Typography>
                    </a>
                  </Link>
                  <Link href={Page.pedidos}>
                    <a>
                      <Typography variant="body-regular">Pedidos</Typography>
                    </a>
                  </Link>
                  <Link href={Page.myAccountInvoice}>
                    <a>
                      <Typography variant="body-regular">Faturas</Typography>
                    </a>
                  </Link>
                  <Link href={Page.carrinhos}>
                    <a onClick={onClickDropdownMyCarts}>
                      <Typography variant="body-regular">Carrinhos</Typography>
                    </a>
                  </Link>
                </>
              ) : (
                <>
                  <Button onClick={() => handleGoToLoginModal()}>Entrar</Button>
                  {loginRegisterModalStatus === ModalOpenEnum.LOGIN_MODAL && loginModalRender()}
                  <a className={styles.registerButton} onClick={() => handleGoToRegisterModal()}>
                    <Typography variant="body-regular" weight="medium">
                      Cadastre-se
                    </Typography>
                  </a>
                  {loginRegisterModalStatus === ModalOpenEnum.REGISTER_MODAL && registerModalRender()}
                </>
              )}
              <div className={styles.divider} />
            </div>
            <div className={styles['submenu__items']}>
              <Link href={Page.categoryRoot}>
                <a>
                  <Typography variant="body-regular">Categorias</Typography>
                </a>
              </Link>
              {process.env.PECAAI_LP_URL && (
                <Link href={process.env.PECAAI_LP_URL}>
                  <a target="_blank">
                    <Typography variant="body-regular">Empresa</Typography>
                  </a>
                </Link>
              )}
              <Link href={Page.ajuda}>
                <a>
                  <Typography variant="body-regular">Ajuda</Typography>
                </a>
              </Link>
              {process.env.WHATS_URL && (
                <Link href={process.env.WHATS_URL}>
                  <a target="_blank">
                    <Typography variant="body-regular">Contato</Typography>
                  </a>
                </Link>
              )}
              <Link href={Page.privacyTerms}>
                <a>
                  <Typography variant="body-regular">Política de Privacidade</Typography>
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <ProviderChangeModal
        modalOpen={modalOpen}
        toggleModal={() => {
          setModalOpen(!modalOpen);
        }}
        toggleMenu={toggleMenu}
      />
    </>
  );
};

export const HeaderMobile = ({
  className,
  darkBandText,
  yellowBandText,
  showDarkBand,
  showYellowBand,
}: HeaderMobileProps) => {
  const [menu, setMenu] = useState(false);
  const { user, userPopulation } = useContext(AuthenticationContext);
  const router = useRouter();
  const toggleMenu = () => setMenu(!menu);
  const { getTotalItemsInTheCart } = useCart();
  const cartQuantity = getTotalItemsInTheCart();
  const mixpanelAnalytics = useMixpanelAnalytics();

  // Close menu when moving o another page
  useEffect(() => {
    const closeMenu = () => setMenu(false);
    router.events.on('routeChangeComplete', closeMenu);
    return () => {
      router.events.off('routeChangeComplete', closeMenu);
    };
  }, []);

  // Prevent scroll on page when mobile menu is open
  useEffect(() => {
    if (window.innerWidth < 992) {
      menu ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'auto');
    }
  }, [menu]);

  const onClickHeaderMyCarts = () => {
    mixpanelAnalytics.click('header_saved_carts', { population: userPopulation });
  };

  return (
    <div className={className}>
      <div className={styles.header}>
        <div className={styles['header__main']}>
          <Link href={Page.home}>
            <a className="d-inline-flex d-flex align-items-center">
              <LogoMobile />
            </a>
          </Link>
          <div className={styles['container-icons']}>
            {user && (
              <>
                <Link href={Page.carrinhos}>
                  <a onClick={onClickHeaderMyCarts}>
                    <BookmarkIcon size={32} color="secondary" />
                  </a>
                </Link>
                <Link href={Page.cart}>
                  <a className="position-relative">
                    <CartMobileIcon size={32} color="white" className={styles['cart-icon']} />
                    {cartQuantity > 0 && (
                      <Typography className={styles['cart-quantity']} weight="bold">
                        {cartQuantity}
                      </Typography>
                    )}
                  </a>
                </Link>
              </>
            )}
            <MenuIcon className="ml-2" size={32} color="secondary" colorProperty="stroke" onClick={toggleMenu} />
          </div>
        </div>

        <SubHeader
          yellowBandText={yellowBandText}
          darkBandText={darkBandText}
          showDarkBand={showDarkBand}
          showYellowBand={showYellowBand}
        />
      </div>
      <HeaderMobileMenu toggleMenu={toggleMenu} isOpen={menu} />
    </div>
  );
};

export default HeaderMobile;
